<template>
  <div>
    <v-app-bar color="transparent" fixed elevate-on-scroll>
      <v-container class="d-flex flex-row align-center" color="primary">
        <v-app-bar-nav-icon
          class="d-flex d-sm-none"
          @click="drawer = true"
        ></v-app-bar-nav-icon>
        <div width="60" height="60" class="mr-8">
          <v-img width="50" src="@/assets/logo.png" />
        </div>
        <v-spacer></v-spacer>
        <v-tabs
          class=""
          height="64"
          background-color="transparent"
          dark
          active-class="primary lighten-5"
          color="primary"
        >
          <v-tab>Home</v-tab>
          <v-tab>Services</v-tab>
          <v-tab>Works</v-tab>
        </v-tabs>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-heart</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="n in 5" :key="n" @click="() => {}">
              <v-list-item-title>Option {{ n }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "AppBar",
  data: () => ({
    drawer: false
  })
};
</script>
<style lang="scss">
header.v-sheet.theme--light.v-toolbar.v-app-bar.v-app-bar--elevate-on-scroll.v-app-bar--fixed.v-app-bar--is-scrolled {
  background: #ffffff !important;
}
</style>
